
/* -------------------------------------------------------------- */
/* Estilos propios para la el producto afiliados.anisoc.encuestas */
/* -------------------------------------------------------------- */

/* Estilos */

.formAnisoc {
    //padding: 1rem 0;

    &_title {
		font-size: clamp(1.5rem, 1.1807rem + 1.1881vw, 2.25rem) !important;
	}

    &_description {
		margin: 2rem 0;
	}

    &_form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    fieldset {		
		display: flex;
		flex-direction: column;
		//gap: 1rem;
		//padding: 1rem;
		//border: 0.125rem solid var(--bs-body-color);
		border: 2px solid #ccc;
		border-radius: 0.25rem;

		&:hover {
			border-color: var(--bs-body-color);
		}

        legend {
            font-weight: 400;
			font-size: 1.25rem;
			padding: 1rem;
			//text-transform: uppercase;
        }

        .field {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			padding: 1rem;
			background-color: #f1f1f1;
		}

		.alert {
			margin-bottom: inherit;
		}

		textarea {
			border-radius: 0.25rem;

		}

		// input[type="radio"] {
		// 	&:hover,
		// 	&:focus {
		// 		outline: 2px solid #007B22 !important;
		// 	}
		// }

    }

	.form-control {
		&:hover,
		&:focus {
			background-color: var(--clr-primary) !important;
			color: #fff !important;
			border-color: #fff;
    		outline: 0.125rem solid #333;
			box-shadow: none;
		}
	}

	.btn {
		&:hover,
		&:focus {
			background-color: var(--clr-primary) !important;
			color: #fff !important;
			border-color: #fff;
    		outline: 0.125rem solid #333;
			box-shadow: none;
		}
	}
}
